import create from 'zustand'
import axios from 'axios';

const useAxiosStore = create((set, get) => ({
    axiosInstance: axios.create({
      baseURL: 'https://eye.its.xyz/api/v1/',
      // baseURL: 'http://127.0.0.1:5000/api/v1/',
    }),
    setAxiosToken: (newToken) => {
        const instance = get().axiosInstance
        instance.defaults.headers.common['Authorization'] = "Bearer " + newToken
    },
    isAuthorized: () => {
        const instance = get().axiosInstance
        return !!instance.defaults.headers.common['Authorization']
    }
  }))

export default useAxiosStore;
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Paper, Slider } from '@mui/material';
import Stack from '@mui/material/Stack';
import preset_1 from '../../assets/preset_1.png';
import preset_2 from '../../assets/preset_2.png';
import preset_3 from '../../assets/preset_3.png';
import preset_4 from '../../assets/preset_4.png';
import toBase64 from '../FileDropzone/utils';
import { b64toBlob } from '../../utils/image';
import useImageStore from '../../hooks/imageStore';
import ImageDropzone from '../FileDropzone';
import useStyleStore from '../../hooks/styleStore';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function BottomTools() {
  const [expanded, setExpanded] = useState('images');
  const [selectedIdx, setIdx] = useState(-1);
  const selectedImage = useImageStore((state) => state.image);
  const setSelectedImage = useImageStore((state) => state.populateImage);
  const { opacity, setOpacity } = useStyleStore();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const segmentation = [
    { id: 0, image: preset_1 },
    { id: 1, image: preset_2 },
    { id: 2, image: preset_3 },
    { id: 3, image: preset_4 },
  ];

  const getStyle = (idx) => {
    return {
      boxSizing: 'border-box',
      border: idx === selectedIdx ? 'solid #2196f3 0.5px' : 'solid black 0.5px',
      opacity: idx === selectedIdx ? '0.7' : '1',
      cursor: 'pointer',
    };
  };
  const onDrop = (files) => {
    const myRequest = new Request(files);

    fetch(myRequest)
      .then((response) => response.blob())
      .then((files) => toBase64(files))
      .then(
        (base64img) => {
          const blob = b64toBlob(base64img, 'image/png');
          const i = new Image();
          i.onload = function () {
            setSelectedImage({ base64img, imageSize: [i.width, i.height] });
          };
          i.src = URL.createObjectURL(blob);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleClick = (idx) => {
    setIdx(idx);
    onDrop(segmentation[idx].image);
  };

  const handleOpacityChange = (event, newValue, activeThumb) => {
    setOpacity(newValue);
  };

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'BottomTools',
  });

  return (
    <Box sx={{ width: '100%', height: 'fit-content' }}>
      <Accordion
        expanded={expanded === 'images'}
        onChange={handleChange('images')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> {t('img')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            {segmentation.map(({ id, image }) => (
              <div
                key={id}
                className="carousel-item"
                style={getStyle(id)}
                onClick={() => handleClick(id)}
              >
                <img
                  id={`img_${id}`}
                  src={image}
                  alt="Example"
                  width={130}
                  height={'100%'}
                />
              </div>
            ))}
            <ImageDropzone customStyle={{ maxWidth: '150px' }} />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'tools'}
        onChange={handleChange('tools')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography> {t('toolbar')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: '180px',
            }}
          >
            <Typography id="input-slider" gutterBottom>
              {t('transparency')}
            </Typography>
            <Slider
              aria-label={t('transparency')}
              defaultValue={0.5}
              onChange={handleOpacityChange}
              valueLabelDisplay="auto"
              value={opacity}
              step={0.1}
              marks
              min={0}
              max={1}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

import React from "react";
import {Routes, Route} from 'react-router-dom';
import MainPage from './pages/main/main'


const App = () => {
    return(
        <>
            <Routes>
                <Route path ='/' element={<MainPage/>}/>
                <Route path ='/auth' element={<MainPage auth={true}/>}/>
            </Routes>
        </>
    );

}

export default App;
import React, {  useEffect } from "react";
import useMapStore from "../../hooks/mapStore";
import ImageLayer from "ol/layer/Image";


export const RasterLayer = ({ source, name }) => {
  const map = useMapStore((state) => state.map);
  useEffect(() => {
    if (!map) return;
    const raster = new ImageLayer({
      renderMode: 'image',
      source: source,
    })
    map.addLayer(raster);
    return () => map.removeLayer(raster);
  }, [source]);
  return null;
};
export default function toBase64(blob) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);

            fileReader.onload = () => {
                resolve(fileReader.result.split(',')[1]);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

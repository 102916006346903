import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import useMapStore from '../../hooks/mapStore';
import { useTranslation } from 'react-i18next';

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function MetricsTable({ markup }) {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'MetricsTable',
  });

  const columns = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },
    // { field: 'label', headerName: `${t('class')}`, width: 85 },
  ];

  const rows = markup.map((e) => e.getProperties());

  rows[0] &&
    rows[0]['label'] &&
    columns.push({
      valueGetter: (params) =>
        localStorage.getItem('i18nextLng') === 'en'
          ? params.row['label']
              .replace('Средний', 'Medium')
              .replace('Большой', 'Large')
          : params.row['label'],
      field: 'label',
      headerName: `${t('class')}`,
      width: '85',
    });

  rows[0] &&
    rows[0]['measures'] &&
    Object.keys(rows[0]['measures']).map((key) =>
      columns.push({
        valueGetter: (params) =>
          Math.round(params.row['measures'][key] * 100) / 100,
        field: key,
        headerName:
          localStorage.getItem('i18nextLng') === 'en'
            ? key
                .replace('Ширина', 'Width')
                .replace('Длина', 'Length')
                .replace('мкм', 'mkm')
                .replace('пиксели', 'pixels')
            : key,
        width: '110',
      })
    );
  const { selectedFeature, setSelectedFeature } = useMapStore();

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        onSelectionModelChange={setSelectedFeature}
        components={{
          Toolbar: GridToolbar,
        }}
        selectionModel={selectedFeature}
      />
    </div>
  );
}

export const ClassMetrics = ({ metrics }) => {
  let cnt = 0;
  for (const e of metrics) {
    e['id'] = cnt;
    cnt += 1;
  }
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'ClassMetrics',
  });

  const columns = [
    { field: 'class', headerName: `${t('metric')}`, width: 210 },
    { field: 'Большой', headerName: `${t('larges')}`, width: 100 },
    { field: 'Средний', headerName: `${t('mediums')}`, width: 100 },
    { field: 'id', headerName: 'id', width: 90, hide: true },
  ];

  const data = [];
  const keys = (metrics[0] && Object.keys(metrics[0])) || [];
  for (const key of keys) {
    data.push({ class: key });
  }

  for (const metric of metrics) {
    for (let cnt = 0; cnt < keys.length; cnt += 1) {
      const row = data[cnt];
      let metricValue = metric[row['class']];

      if (typeof metricValue !== 'string') {
        metricValue = Math.round(metricValue * 100) / 100;
      }
      row[metric['class']] = metricValue;
      row['id'] = cnt;
    }
  }

  for (let cnt = 0; cnt < keys.length; cnt += 1) {
    const row = data[cnt];
    if (localStorage.getItem('i18nextLng') === 'en') {
      row['class'] = row['class']
        .replace('ширина', 'width')
        .replace('Суммарная', 'Total')
        .replace('длина', 'length')
        .replace('Средняя', 'Average')
        .replace('мкм', 'mkm')
        .replace('пиксели', 'pixels');
    }
  }

  data.length > 1 && data.splice(0, 1) && data.splice(data.length - 1, 1);

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

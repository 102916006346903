import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MetricsTable, { ClassMetrics } from '../MetricsTable';

export const MetricsZone = ({
  open,
  drawerWidth,
  markup,
  onClose,
  clsMetrics,
}) => {
  const [tabIdx, setTabIdx] = useState(0);
  // const drawerWidth = 400
  const DrawerHeader = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }));
  const handleChange = (event, newValue) => {
    setTabIdx(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'MetricsZone',
  });

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        height: '97vh',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={onClose}>{<ChevronRightIcon />}</IconButton>
        <Tabs
          value={tabIdx}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={t('class')} />
          <Tab label={t('vessel')} />
          <Tab label={t('markup')} disabled />
        </Tabs>
      </DrawerHeader>
      <Divider />
      <TabPanel value={tabIdx} index={0}>
        <ClassMetrics metrics={clsMetrics} />
      </TabPanel>
      <TabPanel value={tabIdx} index={1}>
        <MetricsTable markup={markup} />
      </TabPanel>
    </Drawer>
  );
};

import create from "zustand";
const useMapStore = create((set, get) => ({
  map: null,
  selectedFeature: [],
  hoveredFeature: null,
  vectorLayer: null,
  populateMap: (mapGenerated) => set((state) => ({ ...state, map: mapGenerated })),
  setVectorLayer: (newLayer) => set((state) => ({ ...state, vectorLayer: newLayer })),
  setSelectedFeature: (newFeature) => {
    console.log(newFeature)
    set((state) => ({ ...state, selectedFeature: newFeature })
  )},
  addSelectedFeature: (featureId) => {
    set((state) => ({
      selectedFeature: [
        ...state.selectedFeature,
        featureId
      ],
    }));
  },
  removeSelectedFeature: (id) => {
    set((state) => ({
      selectedFeature: state.selectedFeature.filter((feature) => feature !== id),
    }));
  },
  setHoveredFeature: (hoveredFeature) => set((state) => ({ ...state, hoveredFeature: hoveredFeature })),
  removeMap: () => set({ map: null }),
}));

export default useMapStore;
import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import { Projection } from 'ol/proj';
import { getCenter } from 'ol/extent';
import './index.css';
import { Box, Paper, Tooltip } from '@mui/material';
import useMapStore from '../../hooks/mapStore';
import BottomTools from '../BottomTools';
import useImageStore from '../../hooks/imageStore';
import { useTranslation } from 'react-i18next';

export const ImageViewer = ({ children, projection, center }) => {
  const setMap = useMapStore((state) => state.populateMap);
  const destroyMap = useMapStore((state) => state.removeMap);
  const mapId = useRef();
  const selectedImage = useImageStore((state) => state.image);
  const { hoveredFeature } = useMapStore();

  useEffect(() => {
    const extent = [
      0,
      0,
      selectedImage.imageSize[0],
      selectedImage.imageSize[1],
    ];
    const projection = new Projection({
      // code: 'xkcd-image',
      // units: 'pixels',
      extent: extent,
    });

    let theMap = new Map({
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: -1,
        maxZoom: 8,
      }),
    });

    theMap.setTarget(mapId.current);
    setMap(theMap);

    return () => {
      if (!theMap) return;
      theMap.setTarget(undefined);
      destroyMap();
    };
  }, []);

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'ImageViewer',
  });

  return (
    <Box
      sx={{
        width: '80vw',
        height: '97vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Tooltip
        title={
          hoveredFeature ? (
            <React.Fragment>
              <Box>
                <p>
                  {`id: ${
                    localStorage.getItem('i18nextLng') === 'ru'
                      ? hoveredFeature.get('id')
                      : hoveredFeature
                          .get('id')
                          .replace('Средний', 'Medium')
                          .replace('Большой', 'Large')
                  }`}
                </p>
                <p>
                  {`${t('width')}: ${
                    Math.round(
                      hoveredFeature.get('measures')['Ширина (мкм)'] * 100
                    ) / 100
                  }`}
                </p>
                <p>
                  {`${t('height')}: ${
                    Math.round(
                      hoveredFeature.get('measures')['Длина (мкм)'] * 100
                    ) / 100
                  }`}
                </p>
              </Box>
            </React.Fragment>
          ) : (
            <></>
          )
        }
        open={!!hoveredFeature}
        followCursor
        enterDelay={500}
        leaveDelay={0}
        sx={{ marginTop: '102px' }}
      >
        <Paper
          ref={mapId}
          className="map"
          sx={{ elevation: 3, height: '79vh', width: '100%' }}
        >
          {children}
        </Paper>
      </Tooltip>
      <BottomTools />
    </Box>
  );
};

export default ImageViewer;

import React from 'react';
import {
  Alert,
  Box,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Snackbar,
} from '@mui/material';
import ImageDropzone from '../FileDropzone';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.svg';

const SideMenu = (props) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'SideMenu',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '97vh',
        flex: '2 0',
        '& > :not(style)': {
          m: 0,
        },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          height: '100%',
          margin: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              style={{ padding: 0, margin: 2 }}
            >
              <img src={logo} alt="logo" width={'50%'} />
              {/*<p> Сегментация сосудов глаза </p>*/}
            </ListSubheader>
          }
        >
          <ListItemButton disabled>
            <ListItemIcon>{/*<SendIcon />*/}</ListItemIcon>
            <ListItemText primary={t('projects')} />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>{/*<DraftsIcon />*/}</ListItemIcon>
            <ListItemText primary={t('presets')} />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>{/*<InboxIcon />*/}</ListItemIcon>
            <ListItemText primary={t('models')} />
          </ListItemButton>
        </List>
        {/*<Snackbar open={true} autoHideDuration={6}>*/}
        {/*    <Alert severity="info" sx={{ width: '100%' }}>*/}
        {/*      Чтобы начать просто загрузите своё изображение или выберите одно из готовых*/}
        {/*    </Alert>*/}
        {/*</Snackbar>*/}
        <ImageDropzone />
      </Paper>
    </Box>
  );
};
export default SideMenu;

import React from 'react';
import Dropzone from 'react-dropzone';
import { b64toBlob } from '../../utils/image';
import toBase64 from './utils';
import useImageStore from '../../hooks/imageStore';
import UploadIcon from '@mui/icons-material/Upload';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ImageDropzone = ({ customStyle }) => {
  const setSelectedImage = useImageStore((state) => state.populateImage);
  const onDrop = (files) => {
    toBase64(files[0]).then(
      (base64img) => {
        const blob = b64toBlob(base64img, 'image/png');
        const i = new Image();
        i.onload = function () {
          setSelectedImage({ base64img, imageSize: [i.width, i.height] });
        };
        i.src = URL.createObjectURL(blob);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'ImageDropzone',
  });

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                cursor: 'pointer',
                padding: '10px',
                textAlign: 'center',
                ...customStyle,
              }}
            >
              <input {...getInputProps()} />
              <Typography>{t('drop')} </Typography>
              <UploadIcon />
            </div>
          </section>
        )}
      </Dropzone>
      <Link
        style={{ marginBottom: '10px' }}
        href={'https://s3.itsociety.su/minio/datasets/EYE/test_images/'}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {t('examples')}
        </Typography>
      </Link>
    </div>
  );
};
export default ImageDropzone;

import React, { useEffect, useState } from 'react';
import './main.css';
import ImageViewer from '../../components/ImageViewer/imageViewer';
import { GeoJSON } from 'ol/format';
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Snackbar,
} from '@mui/material';
import { RasterLayer } from '../../components/ImageViewer/ImageLayer';
import { b64toBlob } from '../../utils/image';
import Static from 'ol/source/ImageStatic';
import { Projection } from 'ol/proj';
import Layers from '../../components/ImageViewer/layers';
import VectorSource from 'ol/source/Vector';
import { MarkupLayer } from '../../components/ImageViewer/VectorLayer';
import { MetricsZone } from '../../components/MetricsZone';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import useImageStore from '../../hooks/imageStore';
import SideMenu from '../../components/SideMenu';
import SignIn from '../../components/Signin';
import { useNavigate } from 'react-router-dom';
import View from 'ol/View';
import { getCenter } from 'ol/extent';
import useMapStore from '../../hooks/mapStore';
import useAxiosStore from '../../hooks/axiosStore';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTranslation } from 'react-i18next';

const MainPage = ({ auth }) => {
  const selectedImage = useImageStore((state) => state.image);
  const setSelectedImage = useImageStore((state) => state.populateImage);
  const [markup, setMarkup] = useState([]);
  const [clsMetrics, setClsMetrics] = useState([]);
  const [imageSource, setImageSource] = useState(null);
  const [projection, setProjection] = useState(null);
  const [vectorSource, setVectorSource] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [demoSnack, setDemoSnack] = React.useState(false);
  const [attemptsLeft, setAttemptsLeft] = React.useState(-1);
  const [isAuthOpen, setIsAuthOpen] = React.useState(auth);
  const axios = useAxiosStore((state) => state.axiosInstance);
  const isAuthorized = useAxiosStore((state) => state.isAuthorized);
  const map = useMapStore((state) => state.map);

  let navigate = useNavigate();

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'MainPage',
  });
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (isAuthOpen) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  }, [isAuthOpen]);

  useEffect(() => {
    if (selectedImage.base64img === '') return;
    setMarkup([]);
    setSnackBar(true);
    axios
      .post('models/predict', {
        image: {
          base_64_image: selectedImage.base64img,
        },
        model: {
          model_name: 'test_model',
        },
      })
      .then(function (response) {
        const polygons = Object.values(response['data']['polygons']);
        const features = {
          type: 'FeatureCollection',
          properties: { object_type: 'annotation', isLocked: false },
          features: polygons.map((f) => ({
            type: 'Feature',
            geometry: f,
            properties: f.properties,
          })),
        };
        setMarkup(new GeoJSON().readFeatures(features));
        setClsMetrics(response['data']['class_measures']);
        setOpen(true);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setSnackBar(false);
      });

    const blob = b64toBlob(selectedImage.base64img, 'image/png');
    const extent = [
      0,
      0,
      selectedImage.imageSize[0],
      selectedImage.imageSize[1],
    ];
    const projection = new Projection({
      extent: extent,
    });
    map.setView(
      new View({
        projection: projection,
        center: getCenter(extent),
        zoom: 1,
        maxZoom: 8,
      })
    );

    setProjection(
      new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent,
      })
    );
    setImageSource(
      new Static({
        url: URL.createObjectURL(blob),
        imageExtent: extent,
        projection,
      })
    );
  }, [selectedImage]);

  useEffect(() => {
    setVectorSource(
      new VectorSource({
        features: markup,
        projection: projection,
      })
    );
  }, [markup]);

  useEffect(() => {
    if (isAuthorized()) {
      return;
    }
    axios.get('models/attempts').then((response) => {
      setAttemptsLeft(response.data.left);
      setDemoSnack(true);
    });
  }, [markup]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '97vh',
        gap: '10px',
      }}
    >
      <SignIn open={isAuthOpen} onClose={() => setIsAuthOpen(false)} />
      {!open && <SideMenu setSelectedImage={setSelectedImage} />}
      <ImageViewer>
        <Layers>
          <RasterLayer source={imageSource} />
          <MarkupLayer source={vectorSource} />
        </Layers>
      </ImageViewer>
      {!open && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            {localStorage.getItem('i18nextLng') === 'en' ? (
              <IconButton
                sx={{ fontSize: '15px' }}
                onClick={() => changeLanguage('ru')}
              >
                EN
              </IconButton>
            ) : (
              <IconButton
                sx={{ fontSize: '15px' }}
                onClick={() => changeLanguage('en')}
              >
                RU
              </IconButton>
            )}
          </Box>
          <Box>
            <IconButton onClick={() => setIsAuthOpen(true)}>
              {!isAuthorized() && (
                <LockIcon color={attemptsLeft === 0 ? 'error' : 'warning'} />
              )}
              {isAuthorized() && <LockOpenIcon color="success" />}
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={() => setOpen(true)}>
              <AnalyticsIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
      )}
      <MetricsZone
        open={open}
        drawerWidth={open ? 450 : 0}
        markup={markup}
        clsMetrics={clsMetrics}
        onClose={() => setOpen(false)}
      />
      <Snackbar open={snackBar} onClose={() => setSnackBar(false)}>
        <Alert severity="info" sx={{ width: '100%' }}>
          {t('img')} <LinearProgress />
        </Alert>
      </Snackbar>
      <Snackbar
        open={demoSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setDemoSnack(false)}
      >
        <Alert severity="warning" sx={{ width: '100%' }}>
          <> {`${t('demo')} ${attemptsLeft} ${t('attempts')}`} </>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MainPage;

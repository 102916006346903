import React, {useEffect, useRef, useState} from "react";
import useMapStore from "../../hooks/mapStore";
import VectorLayer from "ol/layer/Vector";
import {Fill, Stroke, Style} from 'ol/style.js';
import VectorSource from "ol/source/Vector";
import useStyleStore from "../../hooks/styleStore";


export const MarkupLayer = ({ source, name }) => {
  const {map,
    vectorLayer, setVectorLayer,
    hoveredFeature, setHoveredFeature, selectedFeature, setSelectedFeature,
  } = useMapStore()
  const { selectStyle, hoveredStyle, opacity } = useStyleStore()

  const addSelectedFeature = useMapStore((state) => state.addSelectedFeature)
  const prevSelectedFeature = useRef();
  const prevHoveredFeature = useRef();

  const style = new Style({
    fill: new Fill({
      color: '#eeeeee',
    }),
  });
  const styleFunc = function (feature) {
    const label = feature.get('label');
    let color = label === 'Большой' ? `rgba(240, 20, 20, ${opacity})` : `rgba(20, 240, 50, ${opacity})`
    style.getFill().setColor(color);
    return style;
  }


  const onFeatureClick = function (vectorLayer, pixel) {
    vectorLayer.getFeatures(pixel)
    .then(function (features) {
      const feature = features.length ? features[0] : undefined;
      if (feature) {
        addSelectedFeature(feature.get("id"))
      }
      else {
        // setSelectedFeature([])  // TODO: Раскоментировать и понять, почему на клик отправляется 3 события, 2 из которыхх не находят сосуд
      }
    });
  };

  const onFeatureHover = function (vectorLayer, pixel) {
    vectorLayer.getFeatures(pixel)
    .then(function (features) {
      const feature = features.length ? features[0] : undefined;
      if (selectedFeature && feature && selectedFeature.includes(feature.get('id'))) {
        return
      }
      setHoveredFeature(feature)
    });
  };

  useEffect(() => {
    vectorLayer && vectorLayer.getSource().forEachFeature(
        (f) => {
          if(selectedFeature.includes(f.get('id'))){
            f.setStyle(selectStyle)
          }
          else {
            f.setStyle(styleFunc)
          }
        }
        )
  }, [selectedFeature, opacity])

  useEffect(() => {
    if (!hoveredFeature) {
      if(prevHoveredFeature.current && !selectedFeature.includes(prevHoveredFeature.current.get("id"))){
        prevHoveredFeature.current && prevHoveredFeature.current.setStyle(styleFunc)
      }
      prevHoveredFeature.current = null
      return
    }
    if (prevHoveredFeature.current === hoveredFeature) {
    }
    else if (selectedFeature && selectedFeature.includes(hoveredFeature.get("id"))){

    }
    else {
      hoveredFeature.setStyle(hoveredStyle)
    }
    prevHoveredFeature.current = hoveredFeature
  }, [hoveredFeature])

  useEffect(() => {
    if (!map) return;
    let layer = new VectorLayer({
      source,
      name,
      style: styleFunc,
    });

    map.addLayer(layer);

    map.on('pointermove', function (evt) {
      if (evt.dragging) {
        return;
      }
      const pixel = map.getEventPixel(evt.originalEvent);
      onFeatureHover(layer, pixel);
    });

    map.on('click', function (evt) {
      onFeatureClick(layer, evt.pixel);
    });

    setVectorLayer(layer)

    return () => {
      map.removeLayer(layer);
    }
  }, [source]);
  return null;
};

import create from "zustand";
import {Fill, Stroke, Style} from "ol/style";


const getSelectStyle = (opacity) => {
  return new Style({
    stroke: new Stroke({
      color: `rgba(255, 255, 255, ${opacity})`,
      width: 2,
    }),
    fill: new Fill({
      color: `rgba(255, 255, 255, ${opacity*0.6})`,
    }),
  })
}
const getHoveredStyle = (opacity) => {
  return new Style({
    stroke: new Stroke({
      color: `rgba(255, 255, 255, ${opacity})`,
      width: 2,
    }),
    fill: new Fill({
      color: `rgba(40, 160, 190, ${opacity*0.6})`,
    }),
  })
}

const useStyleStore = create((set, get) => ({
  opacity: 0.5,
  selectStyle: getSelectStyle(0.5) ,
  hoveredStyle: getHoveredStyle(0.5) ,

  setOpacity: (newValue) => {
    console.log(newValue)
    set((state) => ({ ...state,
      opacity: newValue,
      selectStyle: getSelectStyle(newValue),
      hoveredStyle: getHoveredStyle(newValue),
    }))
    // set((state) => ({selectStyle: getSelectStyle(newValue)}))
  },
  removeImage: () => set({ map: {base64img: "", imageSize: [1000, 1000]} }),
}));

export default useStyleStore;